.main {
  background: #177fcb;
}

.nav {
  background: #133130;
}

.acc {
  color: #fb4b00;
}

.accBg {
  background: #ff7424;
}

.banner {
  transition: all .3s ease-in-out;
  width: 100%;
  height: 100%;
}

.banner:hover {
  transform: scale(1.03);
}
.mumu {
  /* background: linear-gradient(270deg, #d47fd8, #fcc5ff, #ffdd76, #f98007); */
  /* background-size: 400% 400%; */
  /* background: #f5eca5; */
  /* Apply the animation */
  /* animation: moveGradient 15s ease infinite; */
  /* color: #ff7424; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;

  h2 {
    /* filter: drop-shadow(30px 20px 4px #4444dd); */
    /* filter: drop-shadow(16px 16px 20px); */
    /* background: -webkit-linear-gradient(#ff8400, #fd5005);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 4px 6px pink); */
  }
}

.mainTxt {
  color: orange;
}

.linear-wipe {
  background: linear-gradient(
    to right,
    #fd4415 20%,
    #177fcb 30%,
    #279dcf 70%,
    #ff7801 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}

.socials {
  display: flex;
  align-items: center;

  img {
    margin-right: 37px;

  }
}
@keyframes textShine {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}

.ca {
  padding: 30px;
  text-align: center;
  color: rgb(249, 249, 148);
}

.sub {
  width: 80%;
  margin-top: 30px;
  font-size: 2rem;
  margin-bottom: 30px;
  color: white;
  /* background: #a9f4ffcc;
  filter: drop-shadow(16px 16px 20px); */
  /* background: -webkit-linear-gradient( #ffffff, #ff9f18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.acc-txt {
  /* background: -webkit-linear-gradient(#f9ab2d, #fd5005);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.banTxt {
  font-size: 3rem;
  color: #177fcb;
}

.logo {
  font-size: 2rem;
}

.nav-cont {
  align-items: center;
  display: flex;
}

.foot-cont {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
  height: 627px;
  background: #ff7424;
}

button {
  border-color: #f5ff00;
  color: #f5ff00 !important;
}

button:hover {
  border-color: #fbff00;
  color: #fbff00;
}

.token {
  background: #ff7424;  
  color: white;
}

.footer {
  background: #ff7424;
}

.info {
  padding: 30px 80px;
  width: 100%;
  font-size: 1.5rem;
  color: #ffffff;
  letter-spacing: 2px;
  text-align: center;
  line-height: 44px;
  background: -webkit-linear-gradient(#ffc118, #fd7005);
}

.nav-logo {
  width: 45px;
}

.tp-section {
  background-image: url('/public/tBg.jpg');
  background-size: cover;
}


@media screen and (max-width: 668px) { 
  .banTxt { font-size: 2rem; }
  .logo { font-size: 1.6rem; }
  .sub { font-size: 1.6rem; }
  canvas { height: 300px; }
  .info {
    padding: 10px 10px;
  }
  .nav-logo {
    width: 40px;
  }
}
@media screen and (min-width: 1280px) { 
  .banTxt { font-size: 3rem; }
  .logo {
    font-size: 2rem;
  }
}

@keyframes moveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
